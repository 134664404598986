import type { DefineLocaleMessage } from '@nuxtjs/i18n/dist/runtime/composables';

/**
 * Flatten data
 * @async
 * @param {DefineLocaleMessage} obj
 * @param {string} [prefix='']
 * @returns {Promise<DefineLocaleMessage>}
 */
const nestingToFlatten = async (
  obj: DefineLocaleMessage,
  prefix: string = ''
): Promise<DefineLocaleMessage> => {
  const result: DefineLocaleMessage = {};

  await Promise.all(
    Object.keys(obj).map(async (cur: string) => {
      const pre = prefix ? `${prefix}.` : '';
      if (typeof obj[cur] === 'object') {
        const nestedResult = await nestingToFlatten(obj[cur] as DefineLocaleMessage, pre + cur);
        Object.assign(result, nestedResult);
      } else {
        result[pre + cur] = obj[cur];
      }
    })
  );

  return result;
};

const convertToDictionary = (inputString: string): DefineLocaleMessage => {
  try {
    const resultDict: DefineLocaleMessage = {};
    const lines = inputString.trim().split('\n');

    lines.forEach((line: string) => {
      if (line) {
        // eslint-disable-next-line @typescript-eslint/typedef,
        const [key, ...valueArray] = line.split('=');

        if (valueArray) {
          let value = valueArray.join('=');

          // Trim the value
          value = value.trim();

          // Remove surrounding single or double quotes
          if (
            (value.startsWith("'") && value.endsWith("'")) ||
            (value.startsWith('"') && value.endsWith('"'))
          ) {
            value = value.substring(1, value.length - 1);
          }
          resultDict[key.trim()] = value;
        }
      }
    });
    return resultDict;
  } catch (error) {
    // console.error(error);
  }
  return {};
};

const convertToPropertiesFormat = (obj: any, parentKey: string = ''): string => {
  let propertiesString = '';

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        propertiesString += convertToPropertiesFormat(obj[key], fullKey);
      } else {
        propertiesString += `${fullKey}='${obj[key]}'\n`;
      }
    }
  }

  return propertiesString;
};

export { convertToDictionary, convertToPropertiesFormat, nestingToFlatten };
