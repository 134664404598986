import { type DefineLocaleMessage } from '@nuxtjs/i18n/dist/runtime/composables';

import { fetchI18nByLocaleAtRuntime, timeoutPromise } from '@/utils/locale/i18n.util';
import { convertToDictionary } from '@/utils/locale/locale-object.util';

const TIMEOUT_ERROR: string = 'i18n resource fetch timeout';

/**
 * Return an object of locale messages, merge from CDN + seed-data + default...
 * @param {string} locale The locale of localization
 * @return {Promise<DefineLocaleMessage | undefined>}  Return an object of locale messages, or throw an error if any occurs.
 */
export const getResourcesByLocale = async (
  locale: string
): Promise<DefineLocaleMessage | undefined> => {
  const res = await timeoutPromise<ReturnType<typeof fetchI18nByLocaleAtRuntime>>(
    fetchI18nByLocaleAtRuntime(locale),
    new Error(TIMEOUT_ERROR)
  );

  if (res) {
    const dictionaryData = await convertToDictionary(res);

    return dictionaryData;
  }
  // TODO: SETUP SENTRY
  // nuxtApp.$sentry.captureException(e);
};
